<template>
  <VModalWidget :dismiss="dismiss" :submit="doSubmit" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field
          v-model.trim="form.display"
          :label="$t('displayName')"
          :error-messages="displayErrors"
          type="email"
          required
          @input="$v.form.display.$touch()"
          @blur="$v.form.display.$touch()"
          v-on:keyup.enter="doSubmit"
        />
        <v-text-field v-if="board" :value="board.refname" :label="$t('refname')" readonly />
        <v-text-field v-model.trim="form.description" :label="$t('description')" v-on:keyup.enter="doSubmit" />
        <v-text-field v-if="board" :value="transformDate(board.created_on)" :label="$t('createdOn')" readonly />
        <v-text-field v-if="board" :value="transformDate(board.updated_on)" :label="$t('updatedOn')" readonly />
        <v-text-field
          :value="permaLink"
          :label="$t('permalink')"
          :append-icon="$vuetify.icons.values.copy"
          @click:append="doCopy"
          :hint="copyHint"
          persistent-hint
          readonly
        />
        <v-select v-model="form.meta.settings.view" :items="listviews" hide-no-data :label="$t('prefferedListView')">
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              {{ $t(`listviews.${item}`) }}
            </v-list-item-content>
          </template>

          <template v-slot:item="{ item }">
            <v-list-item-content>
              {{ $t(`listviews.${item}`) }}
            </v-list-item-content>
          </template>
        </v-select>
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { diffObjects, formatDT } from '@argon/app/helpers'
import { required } from 'vuelidate/lib/validators'
import { NAMESPACE } from '@argon/evo/boards/store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { Modal } from '@argon/app/mixins'

export default {
  name: 'ModalProperties',
  mixins: [Modal],
  extends: BaseForm,
  components: {
    VModalWidget,
    FormErrors
  },
  props: {
    boardRef: {
      type: [String, Number],
      required: true
    }
  },
  validations: {
    form: {
      display: { required },
      description: {}
    }
  },
  data: () => ({
    form: {
      display: '',
      description: '',
      meta: {
        settings: {}
      }
    },
    copyHint: '',
    listviews: ['kanban', 'table'],
    submitting: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['board']),
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    flow() {
      return this.flows.find((item) => item.id === this.board.flow_id)
    },
    permaLink() {
      let path = this.$router.resolve({
        name: this.boardLink,
        params: this.$route.params
      }).href
      let hostname = location.host
      return `${hostname}/${path}`
    },
    displayErrors() {
      const errors = []
      if (!this.$v.form.display.$dirty) return errors
      !this.$v.form.display.required && errors.push(this.$t('required'))
      this.getServerErrors('display', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchBoard', 'getBoard']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LLL', tz: this.timezone, locale: this.locale }) : ''
    },
    doCopy: function () {
      this.$copyText(this.permaLink).then(
        (event) => {
          this.copyHint = this.$t('clipboard')
        },
        (error) => {}
      )
    },
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let data = diffObjects(this.form, this.board)
      let payload = { id: this.board.id, data }
      this.patchBoard(payload)
        .then(() => {
          return this.getBoard(this.board.id)
        })
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    if (this.board) {
      // picking nested objects brings along their observable status
      // making using `diffObjects` impossible.
      this.form = {
        display: this.board.display,
        meta: {
          settings: {
            view: this.board.meta.settings ? this.board.meta.settings.view : null
          }
        }
      }
    }
  }
}
</script>

<i18n>
{
  "en": {
    "description": "@:labels.description",
    "clipboard": "Copied to Clipboard",
    "createdOn": "@:labels.createdOn",
    "displayName": "@:labels.displayName",
    "flow": "@:labels.flow",
    "invalid": "@:labels.invalid",
    "permalink": "Permalink",
    "prefferedListView": "@:labels.prefferedListView",
    "refname": "@:labels.refname",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "Edit properties",
    "updatedOn": "@:labels.updatedOn"
  },
  "fr": {
    "description": "@:labels.description",
    "clipboard": "Copié vers le presse-papier",
    "createdOn": "@:labels.createdOn",
    "displayName": "@:labels.displayName",
    "flow": "@:labels.flow",
    "invalid": "@:labels.invalid",
    "permalink": "Permalien",
    "prefferedListView": "@:labels.prefferedListView",
    "refname": "@:labels.refname",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "Modifier les propriétés",
    "updatedOn": "@:labels.updatedOn"
  }
}
</i18n>
